<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('attribute.custom')">
						<v-row dense>
							<v-col cols="4">
								<pui-text-field
									:id="`description-attribute`"
									v-model="model.description"
									:label="$t('attribute.description')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>
							<v-col cols="4">
								<pui-select
									id="typeattributeid"
									attach="typeattributeid"
									:label="$t('attribute.typeattributeid')"
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="attributetype"
									:itemsToSelect="[{ id: model.typeattributeid }]"
									:modelFormMapping="{ id: 'typeattributeid' }"
									itemValue="id"
									itemText="description"
									toplabel
									reactive
								></pui-select>
							</v-col>
							<v-col cols="4">
								<pui-spinner-field
									:id="`${modelName}-sort`"
									:label="$t('attribute.sort')"
									v-model.number="model.sort"
									maxlength="4"
									min="0"
									max="9999"
									toplabel
									:disabled="formDisabled"
									required
								></pui-spinner-field>
							</v-col>
							<v-col cols="4">
								<pui-checkbox
									:id="`disabled`"
									:label="$t('attribute.active')"
									:true-value="0"
									v-model="model.disabled"
									:false-value="1"
									:disabled="formDisabled"
								></pui-checkbox>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
				<v-col cols="12" lg="6" xl="5" v-if="model.typeattributeid === 5">
					<pui-field-set :title="$t('attribute.optionslist')">
						<draggable :list="model.typeattributeoptions" ghost-class="ghost" @start="dragging = true" @end="dragging = false">
							<template v-for="(option, index) in model.typeattributeoptions">
								<div :key="option[index]">
									<p style="display: none">{{ calculateSort(index) }}</p>
									<v-row dense style="height: 40px; margin-bottom: 18px !important">
										<v-col cols="1">
											<v-list-item class="move draggable_point mt-n2">
												<v-icon small>fa fa-arrows</v-icon>
											</v-list-item>
										</v-col>
										<v-col cols="9" sm="8" xl="6">
											<pui-text-field
												:id="`select-${index}-${modelName}`"
												v-model="option.description"
												maxlength="100"
												required
											></pui-text-field>
										</v-col>
										<v-col cols="1" v-if="index !== 0">
											<v-btn
												title="borrar"
												depressed
												color="danger"
												class="elevation-0 option"
												@click.native="removeDetail(index)"
												icon
											>
												<v-icon small class="pointer">fas fa-trash</v-icon>
											</v-btn>
										</v-col>
										<v-col cols="1" v-if="index === model.typeattributeoptions.length - 1">
											<v-btn
												title="crear"
												depressed
												color="success"
												class="elevation-0 option"
												@click.native="addDetail(index)"
												icon
											>
												<v-icon small class="pointer">fas fa-plus</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</div>
							</template>
						</draggable>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'attribute-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'attribute'
		};
	},
	methods: {
		afterGetData() {
			if (this.model.typeattributeoptions.length == 0) this.model.typeattributeoptions = [{}];
			this.parentModelPk = this.$puiUtils.getPathModelMethodPk(this.$router, 'resourcetype');
			this.model.resourcetypeid = this.parentModelPk.pk.resourcetypeid;
			console.log(this.model.resourcetypeid);

			const opts = {
				model: 'attribute',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'resourcetypeid',
							op: 'eq',
							data: this.model.resourcetypeid
						}
					]
				},
				order: [
					{
						column: 'sort',
						direction: 'desc'
					}
				]
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				console.log(response.data.totalRecords);
				if (response.data.data && response.data.totalRecords > 0) {
					this.model.sort = response.data.data[0].sort + 1;
				}
			});
		},
		removeDetail(index) {
			this.model.typeattributeoptions.splice(index, 1);
			if (this.model.typeattributeoptions.length === 0) {
				this.addDetail(index);
			}
		},
		addDetail(index) {
			this.model.typeattributeoptions.push({ sort: index++ });
		},
		calculateSort(index) {
			this.model.typeattributeoptions[index].sort = index + 1;
			return this.model.typeattributeoptions[index].sort;
		}
	},
	computed: {},
	created() {},
	watch: {
		'model.typeattributeid'(newVal) {
			if (newVal !== 5) {
				this.model.typeattributeoptions = [{}];
			} else if (this.model.typeattributeoptions.length == 0) {
				this.model.typeattributeoptions = [{}];
			}
		},
		'model.sort'(newVal) {
			if (!newVal) {
				this.model.sort = 0;
			}
		}
	}
};
</script>
